<template>
  <div class="membership-joining">
    <div class="joining-cover" style="background-color: var(--sub)">
      <div class="title">
        <img
          alt="ストライプ柄"
          src="@/assets/fushimi-HP-pattern001.png"
          class="pc-only"
        />
        <img
          alt="ストライプ柄sp"
          src="@/assets/fushimi-HP-pattern001sp.png"
          class="sp-only"
        />
        <h2 style="font-family: '游明朝','游明朝体'">今すぐ伏見会に参加する</h2>
      </div>
      <p>
        伏見嘉洋公式LINEを友だち追加し、<br  class="tb-only"/>「サロン名、サロンコード、お名前（フルネーム）、役職（オーナー、スタッフ）」をお送りください。<br />こちらで確認後、伏見会へご招待します。
      </p>
      <UiAddButton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiAddButton from "../Ui/AddButton.vue";

export default {
  name: "membership-joining",
  components: {
    UiAddButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.joining-cover {
  padding: 20px 0 50px;
  text-align: center;


  .title {
    position: relative;
    margin-top: 50px;
    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      @media screen and (max-width: 767px) {
        width: 100%;
        top: 43%;
      }
    }
  }
  p {
    padding-top: 20px;
      @media screen and (max-width: 767px) {
    text-align: left;
    padding: 0 10px;
  }
  }
}
</style>
