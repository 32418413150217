<template>
  <div class="membership01">
    <div style="padding-top: 50px">
      <MembershipInvitation />
    </div>

    <div class="space-200 non-member" style="background-color: var(--main)">
      <div class="wrap">
        <h2 style="font-family: '游明朝','游明朝体'">非加盟サロンの方へ</h2>
        <p>
          当コミュニティ「伏見会」は、ドクターリセラの加盟店を対象としたサービスです。現在、ドクターリセラに加盟されていないサロン様で、ドクターリセラの商品について取り扱いについて興味がある場合は、以下のボタンから詳細をご確認ください。
        </p>
        <UiDetailButton
          :type="'C'"
          :path="'/membership02'"
          :text="'詳細を見る'"
        />
      </div>
    </div>

    <div class="space-200 wrap">
      <UiHeading
        :h="'2'"
        :type="'E2'"
        :text="'ダイヤモンドマイスター<br class=sp-only>伏見嘉洋について'"
        class="space-200 space-btm-100"
      />
      <p>
        「肌改善」という分野において、日本でも有数の実力・実績を誇る男性エステティシャン。肌改善への探究心と独自のアプローチの研究と検証によって「結果につながる肌改善のノウハウ」を確立。ドクターリセラ化粧品の使い手として、ドクターリセラ株式会社とのタイアップによって加盟サロンに向けた講師活動を2011年から行っている。岐阜県でキレイ処を4店舗経営。株式会社K・R・I代表取締役。
      </p>
      <UiFushimiHistory class="space-100" :coment="false" />
    </div>

    <div class="space-50 wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'伏見会の運営目的について'" />
      <p class="note">
        伏見会は以下の目的で運営しています。<br
          class="sp-only"
        />賛同いただける場合はぜひご参加ください。
      </p>
      <div class="purpose">
        <p class="back-sub2">
          伏見先生のノウハウの提供による技術の向上、サロン経営の向上
        </p>
        <p class="back-sub">
          伏見先生に質問・相談できる機会を設けることで、サロン運営における不安をなくす
        </p>
        <p class="back-sub2">
          伏見先生の実験や症例データの提供による販促効果の向上など伏見先生からの支援
        </p>
        <p class="back-sub">
          エンドユーザーへの肌改善の結果を提供し、ドクターリセラ化粧品を日本一へ
        </p>
        <p class="back-sub2">
          同じ事業をする、同じビジョンを持つ仲間とのつながり
        </p>
      </div>
    </div>

    <div class="space-150 wrap">
      <MembershipDetail />
    </div>

    <div class="space-150">
      <MembershipActivity />
    </div>

    <div class="space-200 wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'こんな人はぜひ参加を'" />

      <div class="purpose space-50">
        <p class="back-sub2">
          伏見先生からリセラ化粧品の使い方やノウハウを学びたい
        </p>
        <p class="back-sub">
          伏見先生にリセラ化粧品やサロン運営について質問や相談がしたい
        </p>
        <p class="back-sub2">
          伏見先生の症例データや検証データがほしい、サロンやホームページで使いたい
        </p>
        <p class="back-sub">伏見先生の考えやマインドを知りたい</p>
        <p class="back-sub2">
          キレイ処が行っているスタッフ教育について知りたい、学びたい
        </p>
        <p class="back-sub">
          キレイ処が行っている集客・マーケティング・求人について知りたい、学びたい
        </p>
        <p class="back-sub2">伏見先生と会える、話せる場がほしい</p>
        <p class="back-sub">リセラ加盟サロンと交流できる場がほしい</p>
      </div>
    </div>

    <div class="space-200 wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'伏見会への参加条件'" />

      <div class="purpose space-50">
        <ul>
          <li>ドクターリセラ加盟サロンのサロンオーナー、または、サロンオーナーから任命を受けたスタッフであること</li>
          <li>LINEアカウントと連絡が取れるメールアドレスがあること</li>
          <li>当コミュニティの目的に共感いただけること</li>
          <li>
            伏見先生に依存するのではなく、「がんばるのは自分自身」というスタンスで学び、取り組めること
          </li>
          <li>当コミュニティの運営ルールを遵守すること</li>
        </ul>
      </div>

      <div class="space-150">
        <MembershipFlow />
      </div>

      <div class="space-150">
        <MembershipQa />
      </div>

      <!-- <div class="space-200">
        <UiHeading :h="'2'" :type="'E2'" :text="'参加特典のご案内'" />
        <p class="note space-50">
          解説付きで伏見先生自身が行う5分間クレンジングをする様子の動画をプレゼントします。
        </p>
        <p class="note">
          ※電子データでの提供となりますので、<br
            class="sp-only"
          />ご指定のメールアドレスにお送りします。
        <img
          alt="特典データイメージ"
          src="@/assets/youtube.jpg"
          class="note-img"
        />
        </p>
      </div> -->

      <div class="space-150 space-btm-100">
        <MembershipJoining />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiDetailButton from "@/components/Ui/DetailButton.vue";
import MembershipInvitation from "@/components/Membership/Invitation.vue";
import UiFushimiHistory from "@/components/Ui/FushimiHistory.vue";
import MembershipDetail from "@/components/Membership/Detail.vue";
import MembershipActivity from "@/components/Membership/Activity.vue";
import MembershipJoining from "@/components/Membership/Joining.vue";
import MembershipFlow from "@/components/Membership/Flow.vue";
import MembershipQa from "@/components/Membership/Qa.vue";

export default {
  name: "Membership01",
  components: {
    MembershipInvitation,
    MembershipDetail,
    MembershipActivity,
    MembershipJoining,
    UiDetailButton,
    UiHeading,
    UiFushimiHistory,
    MembershipFlow,
    MembershipQa,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.non-member {
  color: white;
  text-align: center;
  padding: 50px 0;
  h2 {
    padding-bottom: 30px;
  }
  p {
    text-align: left;
    margin-bottom: 30px!important;
    @media screen and (max-width: 1024px) {
      padding-bottom: 50px;
    }
  }
}
.note {
  text-align: center;
  margin-bottom: 20px !important;

  .note-img {
    width: 70%;
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }
  span {
    @media screen and(max-width: 767px) {
      font-size: 14px;
    }
  }
}
.purpose {
  .back-sub,
  .back-sub2 {
    padding: 20px;
    margin-bottom: 10px !important;
    font-size: 20px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      padding: 10px;
    }
  }
  .back-sub2 {
    background-color: #c6c6c6;
  }
  .back-sub {
    background-color: #ebebeb;
  }
}
</style>
