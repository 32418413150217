import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


import Home from '../views/Home.vue'
import Membership01 from '../views/Membership01.vue'
import Membership02 from '../views/Membership02.vue'
import EmulsifyingCleansing from '../views/EmulsifyingCleansing.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // redirect: '/sample01',
    meta: {
      title: '伏見嘉洋公式ホームページ',
      description: '伏見嘉洋公式ホームページです。男性エステティシャンながら、圧倒的な肌改善の実績によって、ドクターリセラのダイヤモンドマイスターに認定され、全国のエステサロンに向けて、講演・サポート活動をしています。伏見嘉洋が手がける事業である、オンラインショップ、フェイシャル&脱毛サロン「キレ処」、低糖質専門店「結」について紹介しています。'
    }
  },
  {
    path: '/membership01/',
    name: 'membership01',
    component: Membership01,
    // redirect: '/sample01',
    meta: {
      title: 'ドクターリセラ加盟サロン向け教育サービス「伏見会」',
      description: 'ドクターリセラ加盟サロンのサロンオーナー・店長クラスに向け教育サービス「伏見会」について紹介しています。自らがドクターリセラの使い手としてトップに君臨し、キレイ処を4店舗経営している、そのサロン経営のノウハウをすべてお教えするサービスです。'
    }
  },
  {
    path: '/membership02/',
    name: 'membership02',
    component: Membership02,
    // redirect: '/sample01',
    meta: {
      title: '伏見嘉洋によるドクターリセラ販売代理店の加盟に関する相談会',
      description: 'ドクターリセラの販売代理店への加盟を検討しているサロンに向けて、ドクターリセラのNO.1の使い手であり、3ヶ月先に予約まで埋まっているフェイシャルエステサロン「キレイ処」を経営する伏見嘉洋に相談ができるサービスです。事前に伏見嘉洋に相談した上で、加盟を検討・決定することができます。'
    }
  },
  {
    path: '/emulsifying-cleansing/',
    name: 'emulsifying-cleansing',
    component: EmulsifyingCleansing,
    // redirect: '/sample01',
    meta: {
      title: '伏見嘉洋考案の乳化クレンジングについて',
      description: '伏見嘉洋考案の乳化クレンジングについて定義・方法、世の中でいわれているオイル系クレンジングの乳化クレンジングとの違いについて説明しています。伏見式の乳化クレンジングは水溶性ジェルクレンジングのドクターリセラのピュアモイスチャークレンジングを用いて、お顔の皮脂を乳化させて、除去していく方法です。'
    }
  },

  // ワイルドカードのルートをリダイレクトする
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

next();

  // if (to.path.slice(-1) === "/") {
  //   next(to.path.slice(0, -1));
  // } else {
  //   next();
  // }
});

export default router
