<template>
  <div class="cotacnt-form" style="font-family: '游明朝', '游明朝体'">
    <div class="cover">
      <div class="title">
        <div v-if="pageType === 0">
          <h4 style="font-family: '游明朝', '游明朝体'">全項目入力必須</h4>
        </div>
        <div v-if="pageType === 1">
          <h4 style="font-family: '游明朝', '游明朝体'">
            入力内容にお間違いがないかご確認の上、「送信」ボタンを押して下さい。
          </h4>
        </div>
      </div>

      <div class="form" v-if="pageType === 0 || pageType === 1">
        <div class="input-cover">
          <p class="sub-name">
            企業名・屋号名<span>（ない場合は「なし」と記載）</span>
          </p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.companyName"
              type="text"
              class="row1"
              placeholder="例）株式会社 K・R・I"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.companyName }}
          </div>
        </div>
        <div class="input-cover">
          <p class="sub-name">
            サロン名<span>（ない場合は「なし」と記載）</span>
          </p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.salonName"
              type="text"
              class="row1"
              placeholder="例）キレイ処"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.salonName }}
          </div>
        </div>
        <div class="input-cover">
          <p class="sub-name">お名前</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.name"
              type="text"
              class="row1"
              placeholder="例）山田 花子"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.name }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub-name">電話番号</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.tel"
              type="tel"
              class="row1"
              placeholder="例）090-1234-5678"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.tel }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub-name">連絡先メールアドレス</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.mail"
              type="email"
              class="row1"
              placeholder="例）sample@gmail.com"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.mail }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub-name">現状について</p>
          <div v-if="pageType === 0" class="row-cover radio-bt">
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="0"
              />エステサロン経営者</label
            >
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="1"
              />エステサロン勤務</label
            >
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="2"
              />他業種の経営者</label
            >
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="9"
              />その他</label
            >
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <span v-show="form.type === 0">エステサロン経営者</span>
            <span v-show="form.type === 1">エステサロン勤務</span>
            <span v-show="form.type === 2">他業種の経営者</span>
            <span v-show="form.type === 9">その他</span>
          </div>
        </div>

        <div class="input-cover">
          <p class="sub-name">お問い合わせ内容詳細</p>
          <div v-if="pageType === 0">
            <textarea
              v-model="form.description"
              placeholder="こちらにお問い合わせ内容を入力してください。"
            ></textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.description }}
          </div>
        </div>
      </div>

      <div @click="confirm" class="button" v-if="pageType === 0">
        <!-- <div class="button" v-if="pageType === 0"> -->
        <div><p>確認する</p></div>
      </div>
      <div class="soushin" v-if="pageType === 1">
        <div @click="back" class="button"><p>修正する</p></div>
        <div @click="send" class="button"><p>送信</p></div>
        <!-- @click="send"を本番は復活させること -->
      </div>
      <div v-else-if="pageType === 2" class="complete">
        <h3 style="margin-bottom: 50px; text-align: center">
          お問い合わせを承りました
        </h3>
        <p>
          お問い合わせ内容を、ご入力のメールアドレスに送信しました。<br />20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
        <!-- <router-link to="/"> -->
        <div class="button2" @click="top">トップに戻る</div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CotacntForm",
  components: {},
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      form: {
        companyName: "",
        salonName: "",
        name: "",
        tel: "",
        mail: "",
        type: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {},
  methods: {
    confirm() {
      // alert(this.form.companyName);
      // console.log(`company name: ${this.form.companyName}`);
      // console.log(`company name: ${this.form}`);//※ダメなやつ

      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      //企業名チェック
      if (this.form.companyName !== "") {
        console.log("企業名 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「企業名」";
      }
      //サロン名チェック
      if (this.form.salonName !== "") {
        console.log("サロン名 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「サロン名」";
      }
      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf("hotmail.co.jp");
        var outResult = mailAddress.indexOf("outlook.jp");

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        console.log("電話番号 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //現状についてチェック
      if (this.form.type !== "") {
        console.log("現状について ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「現状について」";
      }
      //購入内容チェック
      // if (this.form.type === 0) {
      //   if (
      //     this.form.bottle1Num !== 0 ||
      //     this.form.bottle2Num !== 0 ||
      //     this.form.bottle3Num !== 0
      //   ) {
      //     console.log("購入内容 ok");
      //   } else {
      //     validation_ok = false;
      //     validation_message = validation_message + "「購入本数」";
      //   }
      // } else {
      //   noaction
      // }
      //内容チェック
      if (this.form.description !== "") {
        console.log("お問い合わせ内容 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ内容」";
      }

      //結果発表
      if (validation_ok === true) {
        this.pageType = 1;
        return;
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }
    },
    send() {
      let params = new URLSearchParams();
      params.append("companyName", this.form.companyName);
      params.append("salonName", this.form.salonName);
      params.append("name", this.form.name);
      params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("type", this.form.type);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.status === 200) {
            console.log(this.form.companyName)
            this.pageType = 2;
            return;
          } else if (
            response.data.status === 400 ||
            response.data.status === 500
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
    },
    top() {
      this.$scrollTo("#app", 500, { easing: "ease" });
      this.pageType = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-bottom: 50px;
  }

  .title {
    padding: 60px 0 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }

    h3 {
      padding-top: 15px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
  }

  .form {
    margin-bottom: 50px;
    margin-top: 20px;

    .input-cover {
      text-align: left;
      margin-bottom: 30px;
      .sub-name {
        font-size: 18px;
        margin-bottom: 5px;
      }
      input,
      textarea {
        border: none;
      }
      .row-cover {
        border: 1px solid #a7a5a4;
      }
      .radio-bt {
        padding: 20px 20px;
        background-color: white;
      }

      .row1,
      textarea {
        width: 100%;
        padding: 20px;
        font-size: 20px;
        resize: auto;
        cursor: text;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        column-count: initial !important;
        word-break: break-all;
        background-color: white;

        @media screen and (max-width: 767px) {
          padding: 10px 20px;
        }
      }

      textarea {
        border: 1px solid #a7a5a4;
        height: 300px;
        text-rendering: auto;

        @media screen and (max-width: 767px) {
          margin: 0;
        }
      }

      .sp {
        &:nth-child(n + 2) {
          margin-left: 20px;
          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        }

        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }
  .button,
  .button2 {
    background-color: var(--sub);
    color: var(--black);
    border: solid 1px var(--black);
    padding: 10px 0;
    width: 250px;
    text-align: center;
    margin: 50px auto;
    cursor: pointer;
    transition: 0.4s;
    p {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 200px;
      padding: 20px 0;
      margin: 40px auto 50px;
    }

    &:hover {
      background-color: white;
      translate: 0.3s;
    }
  }

  .soushin {
    display: flex;
    justify-content: space-between;
    .button,
    .button2 {
      width: 40%;
      font-size: 18px;
    }
  }

  .complete {
    text-align: left;
    h2 {
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
    a {
      text-decoration: none;
      color: black;

      .button2 {
        width: 170px;
        margin-top: 70px;
        margin-bottom: 50px;
      }
    }
    p {
      text-align: center;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}

.confirmation {
  padding: 10px 20px;
  background-color: white;
  border: none;
}
</style>