var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-header"},[_c('div',{staticClass:"header-cover",class:{
      'over-baseline':
        (_vm.path !== '/' && _vm.scrollY >= 0) || (_vm.path === '/' && _vm.scrollY >= 800),
    }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"logo",on:{"click":_vm.top}},[_c('img',{class:{
            'over-baseline2':
              (_vm.path !== '/' && _vm.scrollY >= 0) ||
              (_vm.path === '/' && _vm.scrollY >= 800),
          },attrs:{"src":require("@/assets/fushimi-logo.png"),"alt":"ロゴ"}})]),_c('div',{staticClass:"hamburger"},[_c('UiDrawer')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }