<template>
  <div class="sample-qa-qabox">
    <div class="overall" @click="openContent = !openContent" >
      <UiQaQestion :qestion="qestion" :openContent="openContent" id="top"/>
    </div>
    <transition>
      <div class="answer-cover" v-show="openContent">
        <p v-html="answer" class="answer"></p>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQestion from "@/components/Ui/Qa/Qestion.vue";
export default {
  name: "SampleQaQaBox",
  components: {
    UiQaQestion,
  },
  props: {
    qestion: String,
    answer: String,
  },
  data() {
    return {
      openContent: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.answer-cover{
  padding: 0 50px 20px;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    padding: 10px 20px;
    margin-top: 10px;
  }
  .answer{
    margin-left: 105px;
    text-align: left;
    @media screen and (max-width: 767px){
      margin-left: 0;
    }
  }
}
.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 50px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 50px;
    border: 2px solid var(--green);
    color: var(--green);

    p {
      font-weight: 400;
    }

    &:hover {
      background: var(--green);
      color: white;
    }
  }
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s
}
.v-enter-to {
  opacity: 1;
}
.v-leave {
  opacity: 2;
}
.v-leave-active {
  transition: opacity 0.5s
}
.v-leave-to {
  opacity: 0;
}


</style>