<template>
  <div class="home-activity">
    <img
      alt="ストライプ柄sp"
      src="@/assets/fushimi-HP-pattern001sp.png"
      class="sp-only"
      style="background-color: var(--sub); vertical-align: top"
    />
    <div class="wrap" style="background-color: var(--sub)">
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only"
      />
      <div class="inner">
        <div class="inner-top">
          <img
            alt="伏見嘉洋"
            src="@/assets/fushimi-HP-pattern002.png"
            class="stamp"
          />
        </div>
        <p class="text">
          当ページをご覧いただき、ありがとうございます。乳化クレンジング考案者の伏見嘉洋です。
        </p>

        <img
          alt="乳化クレンジング考案者伏見嘉洋"
          src="@/assets/fushimi-HP-nyuka-001.jpg"
          class="nyuka-img top" style="margin-bottom:0;"
        />

        <p class="text top">
          「肌改善」という分野において、日本でも有数の実力・実績を誇る男性エステティシャン。肌改善への探究心と独自のアプローチの研究と検証によって「結果につながる肌改善のノウハウ」を確立。ドクターリセラ化粧品の使い手として、ドクターリセラ株式会社とのタイアップによって加盟サロンに向けた講師活動を2012年から行っている。岐阜県でキレイ処を4店舗経営。株式会社K・R・I代表取締役。
        </p>
        <p class="text">
          僕は、20歳でエステティシャンとなり、岐阜県でフェイシャルエステサロン「キレイ処」をこれまで20年間に渡って4店舗経営してきました。
        </p>
        <img
          alt="キレイ処"
          src="@/assets/fushimi-HP-nyuka-002.jpg"
          class="nyuka-img"
        />
        <p class="text">
          株式会社ドクターリセラの化粧品を2006年から取り扱い、全国3,000店舗以上のエステサロンが参加する、ドクターリセラが主催する全国大会での入賞回数は10回。これは日本一です。そして、現在は、ドクターリセラ株式会社から、最上級の商号である「ダイヤモンドマイスター」に認定されています。
        </p>
        <img
          alt="ダイヤモンドマイスター"
          src="@/assets/fushimi-HP-nyuka-003.jpg"
          class="nyuka-img"
        />
        <p class="text">
          このページでは、僕がこんな実績を出することできた、その最たる理由である「乳化クレンジング」について説明しています。乳化クレンジングについて、知りたい人はぜひ最後までお読みください。
        </p>
      </div>
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only bottom"
        style="vertical-align: bottom"
      />
    </div>
    <img
      alt="ストライプ柄sp"
      src="@/assets/fushimi-HP-pattern001sp.png"
      class="sp-only bottom"
      style="background-color: var(--sub); vertical-align: top"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox from "../Ui/Box.vue";

export default {
  name: "membership-invitation",
  components: {
    // UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.inner {
  text-align: left;
  padding: 0 100px 50px;
  @media screen and (max-width: 767px) {
    padding: 0;
    padding-bottom: 30px;
  }
  .inner-top {
    position: relative;
    padding-top: 100px;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }

    .stamp {
      position: absolute;
      width: 27%;
      bottom: -100%;
      right: -7%;
      @media screen and (max-width: 767px) {
        width: 35%;
        bottom: 0;
        right: 0;
      }
    }
  }
  .text {
    margin-bottom: 30px !important;
    @media screen and (max-width: 767px) {
    }
  }
  .top {
    font-size: 14px;
    margin-bottom: 0;
  }

  .nyuka-img {
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      margin: 10px 0;
    }
  }

  .bottom {
    vertical-align: bottom;
  }
}
.sp {
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
</style>
