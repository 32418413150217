<template>
  <div class="home-about-fushimi wrap">
    <UiHeading
      :h="'2'"
      :type="'E2'"
      :text="'伏見嘉洋について'"
      class="space-200"
    />
    <div class="youtube-cover">
      <div class="youtube">
        <iframe
          width="100%"
          height="550px"
          src="https://www.youtube.com/embed/FLPYUG4g94c"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
      <UiYoutubeButton />
      <p>
        「肌改善」という分野において、日本でも有数の実力・実績を誇る男性エステティシャン。肌改善への探究心と独自のアプローチの研究と検証によって「結果につながる肌改善のノウハウ」を確立。ドクターリセラ化粧品の使い手として、ドクターリセラ株式会社とのタイアップによって加盟サロンのオーナー・エステティシャンに向けた講師活動を2011年から行っている。岐阜県でキレイ処を4店舗経営。株式会社K・R・I代表取締役。
      </p>
    </div>
    <UiFushimiHistory class="space-200 spac-bottom-150" :coment="false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiFushimiHistory from "@/components/Ui/FushimiHistory.vue";
import UiYoutubeButton from "@/components/Ui/YoutubeButton.vue";

export default {
  name: "HomeMap",
  components: {
    // UiBox,
    UiHeading,
    UiFushimiHistory,
    UiYoutubeButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.youtube-cover {
  margin-top: 50px;
  .youtube {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .youtube iframe {
    width: 100%;
    height: 100%;
  }
  p {
    margin-top: 50px;
  }
}
.contests {
  text-align: center;
  h3 {
    font-size: 16px;
  }
  img {
    width: 70%;
    margin: 50px 0 150px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 50px;
    }
  }
}
.space-200 {
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}
</style>
