<template>
  <div class="emulsifying-cleansing">
    <div class="emulsifying-cleansing-top">
      <div class="wrap space-180p">
        <div class="inner">
          <h1 style="font-family: '游明朝', '游明朝体'">
            伏見嘉洋が考案した<br class="sp-only" />乳化クレンジングについて
          </h1>
          <img
            alt="乳化クレンジング画像"
            src="@/assets/fushimi-HP-nyuka-image002.jpg"
            class="nyuka-img"
          />
          <div class="maru-coment" style="font-family: '游明朝', '游明朝体'">
            <p>
              お顔の皮脂を<br
                class="pc-only"
              />水溶性クレンジングで<br />乳化させて除去
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <EmulsifyingCleansingNyukaFushimi />
    </div>

    <div class="wrap space-btm-100">
      <UiHeading
        :h="'2'"
        :type="'E2'"
        :text="'乳化クレンジングとは'"
        class="space-200 space-btm-100"
      />
      <div class="youtube space-50">
        <iframe
          width="100%"
          height="550px"
          src="https://www.youtube.com/embed/-nDgoWlcgXo"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <UiYoutubeButton />
      </div>
      <div class="text-width">
        <p>
          乳化クレンジングについて、説明するにあたり、まずは「乳化」という言葉の意味を確認していきます。乳化とは、「油や水分のように本来混ざり合わないものが均一に混ざり合う状態のこと」という意味です。この意味を念頭に置いて、引き続きの説明をお読みいただくと、理解がスムーズです。
        </p>
        <p>
          僕が考案した乳化クレンジングをシンプルに定義すると、「毎日出る自分自身のお顔の皮脂と、水溶性のジェルクレンジグを混ぜ合わせて、乳化させることによって、お顔から皮脂を除去していくこと」です。
        </p>
        <p>顔から出る皮脂は、思う以上にお肌に悪い影響を与えています。</p>
        <p>
          女性の方の多くは、普段メイクをしていると思います。メイクを落とさずに、寝てしまい、朝を迎えると、お顔の状態はどうでしょうか？よろしくないですよね。
        </p>
        <p style="margin-bottom:0!important;">
          では、お顔のメイクを落とした状態で寝ると、翌朝どうでしょうか？メイクを落としても、お顔の状態が良くないですよね。これこそ、お顔から出る皮脂による悪い影響です。
        </p>
        <img
          alt="朝イメージ"
          src="@/assets/fushimi-HP-nyuka-004.jpg"
        />
        <p>
          このように「お肌に対して悪い影響を及ぼす皮脂と水溶性ジェルクレンジングを混ぜ合わせることで乳化させてしっかりと除去しましょう、それによってお肌を良い状態にしましょう」というのが伏見式の乳化クレンジングです。
        </p>
      </div>
    </div>

    <div class="skay-blue">
      <EmulsifyingCleansingAboutNyuka />
    </div>

    <div class="wrap space-150 space-btm-200">
      <EmulsifyingCleansingQa />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmulsifyingCleansingNyukaFushimi from "@/components/EmulsifyingCleansing/NyukaFushimi.vue";
import UiYoutubeButton from "@/components/Ui/YoutubeButton.vue";
import EmulsifyingCleansingAboutNyuka from "@/components/EmulsifyingCleansing/AboutNyuka.vue";
import UiHeading from "@/components/Ui/Heading.vue";
import EmulsifyingCleansingQa from "@/components/EmulsifyingCleansing/Qa.vue";

export default {
  name: "Home",
  components: {
    EmulsifyingCleansingNyukaFushimi,
    UiHeading,
    UiYoutubeButton,
    EmulsifyingCleansingAboutNyuka,
    EmulsifyingCleansingQa,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.emulsifying-cleansing-top {
  background-image: url(~@/assets/fushimi-HP-nyuka-image001.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 230px;
  @media screen and (max-width: 767px) {
    background-image: url(~@/assets/fushimi-HP-nyuka-image001sp.jpg);
    padding-bottom: 100px;
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    h1 {
      font-size: 40px;
      @media screen and (max-width: 767px) {
        font-size: 28px;
        text-align: center;
      }
    }
    .nyuka-img {
      width: 40%;
      margin-top: 50px;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-top: 30px;
      }
    }
    .maru-coment {
      width: 310px;
      height: 310px;
      border-radius: 50%;
      border: 1px solid var(--black);
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: 910px) and (max-width: 1024px) {
        border: none;
        display: flex;
        align-items: flex-end;

      }
      @media screen and (max-width: 910px) {
        border: 0;
        width: auto;
        height: auto;
        position: static;
        text-align: center;
        margin-top: 20px;
      }
    }
    p {
      font-size: 20px;
      line-height: 2em;
      // font-weight: bold;
    }
  }
}
.text-width {
  margin-top: 100px;
  padding: 0 100px;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 50px;
  }
  img {
    margin: 50px 0;
    @media screen and (max-width: 767px) {
      margin: 30px 0;
    }
  }
  p{
    margin-bottom: 30px!important;
  }
}
.skay-blue {
  background-color: #ecf8fd;
}

.space-180p {
  padding-top: 180px;
  @media screen and (max-width: 767px) {
    padding-top: 90px;
  }
}
</style>
