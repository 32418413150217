<template>
  <div class="fl-qa">
    <UiHeading :h="'2'" :type="'E2'" :text="'よくある質問'" />
    <div class="qa-list">
      <UiQaQaBox
        v-for="qaBoxListData in qaBoxList"
        :key="qaBoxListData.id"
        :qestion="qaBoxListData.qestion"
        :answer="qaBoxListData.answer"
        class="item"
      />
      <a href="http://"></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiQaQaBox from "../Ui/Qa/QaBox.vue";

export default {
  name: "FlQA",
  components: {
    UiHeading,
    UiQaQaBox,
  },
  props: {},
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion:
            "伏見会はドクターリセラ株式会社が提供するサービスなのですか？",
          answer:
            "キレイ処の運営母体である株式会社K・R・Iが独自に運営するサービスです。ドクターリセラの製品を超え、サロン運営やサロン集客など、サロン経営における内容について学ぶことを目的としたサービスです。",
        },
        {
          id: 2,
          qestion: "参加にお金は発生しますか？",
          answer:
            "参加にはお金は発生しません。伏見会のオープンチャットで告知する有料の講座や企画にお申し込みいただく場合に限って料金が発生します。なお、どの講座や企画に参加するかは、自由に選択できます。",
        },
        {
          id: 3,
          qestion:
            "ドクターリセラ加盟サロンのオーナーではなく、エステティシャンですが、参加できますか？",
          answer:
            "サロンオーナーから任命を受けたエステティシャンであれば参加可能です。サロンオーナー様に伏見会について説明し、承諾を頂いた上で、ご参加をお願いします。",
        },
        {
          id: 4,
          qestion:
            "サロンオーナーですが、ドクターリセラの加盟サロンではありません。参加できますか？",
          answer:
            "当コミュニティは、ドクターリセラ加盟サロンのサロンオーナー限定となっています。非加盟サロンのサロンオーナー様は<a href=https://fushimi-yoshihiro.com/membership02/>こちら</a>をご確認ください。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.fl-qa {
  .qa-list {
    margin-top: 50px;
    .item{
      padding-bottom: 30px;
    }
  }

}
</style>
