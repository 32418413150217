<template>
  <div class="home-invitation">
      <img
        alt="ストライプ柄sp"
        src="@/assets/fushimi-HP-pattern001sp.png"
        class="sp-only stripe"
        style="background-color: var(--sub);vertical-align: top;"
      />
    <div class="wrap sp" style="background-color: var(--sub)">
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only"
      />
      <div class="inner">
        <div class="inner-top">
          <h1 style="font-family: '游明朝','游明朝体'">
            ダイヤモンドマイスター<br class="sp-only tb-only">伏見先生(キレイ処)から学べる<br />
            「伏見会」への参加をお願いします
          </h1>

          <img
            alt="伏見嘉洋"
            src="@/assets/fushimi-HP-011.jpg"
            class="fushimi"
          />
          <img
            alt="スタンプ"
            src="@/assets/fushimi-HP-pattern002.png"
            class="stamp"
          />
        </div>
        <div class="text">
          <p>
            こんにちは、ドクターリセラダイヤモンドマイスターの伏見嘉洋です。
          </p>
          <p>いつも僕の講習を受講していただき、ありがとうございます。</p>
          <p>
            ドクターリセラさんとのタイアップの講習では、毎回、全力で僕のノウハウを一切包み隠さず、お伝えしています。
          </p>
          <p>
           おかげさまで、講習に参加されたサロン経由で、肌改善のレベルが上がり、改善が難しかったお客様の肌も変化が見られ、リセラ商品の販売数も増加し、継続率も向上しているという、うれしいフィードバックをリセラさんからいただいています。
          </p>
          <p>
            しかし、リセラさんの講習を通して僕がお伝えできることにも限りがあるため、日々、僕のSNSやLINEには加盟サロンの方々から質問や相談が届きます。
          </p>
        </div>
        <img
          alt="LINE画像"
          src="@/assets/fushimi-HP-012.jpg"
          class="line-img"
        />

        <div class="text">
          <p>
            このように僕のことを頼りにしていただき、とてもうれしい気持ちでもあり、一方、「もっと自分が知識やノウハウを提供していかなければいけない」という使命感もあります。
          </p>
          <p>
            そこで、このたび、ドクターリセラの加盟サロンのサロンオーナー様とサロンオーナーから任命を受けたスタッフ様に向けて、いつもの講習でお届けしているような「結果を出す方法」をお教えすることをメインに、その他にも僕からサロンの運営に必要なことを直接学べるコミュニティ「伏見会」を立ち上げました。
          </p>
          <p>
            伏見会では、ドクターリセラ化粧品の使い方に関する教育、サロンでのカウンセリング方法、お客様の継続率の向上、サロンへの集客、求人など、僕がこれまでキレイ処を運営してきた経験にもとづいて、サロン運営の必要なことをお教えしていきます。
          </p>
          <p>
            僕がダイヤモンドマイスターとして結果を出していることを中心に、その他にも普段は僕から学べないサロン経営の大切なことをこの伏見会ではお伝えしていきます。
          </p>
          <p>
            参加費は無料なので、興味がある方はぜひ参加してみてください。※ドクターリセラ加盟サロンのサロンオーナー、または、サロンオーナーから任命を受けたスタッフであることが当会の参加条件です。
          </p>
        </div>
        <img
          alt="ストライプ柄"
          src="@/assets/fushimi-HP-pattern001.png"
          class="pc-only bottom"
        />
      </div>
    </div>
        <img
          alt="ストライプ柄sp"
          src="@/assets/fushimi-HP-pattern001sp.png"
          class="sp-only bottom"
          style="background-color: var(--sub);vertical-align: top;"
        />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox from "../Ui/Box.vue";

export default {
  name: "membership-invitation",
  components: {
    // UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.inner {
  text-align: center;
  .stripe{
    padding:0 -20px;
  }
  .inner-top {
    position: relative;
    padding-top: 50px;

    h1{
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .fushimi {
      width: 40%;
      margin: 50px 0;
      @media screen and (max-width: 767px){
        width: 80%;
        margin: 30px 0;
      }
    }
    .stamp {
      position: absolute;
      width: 28%;
      bottom: 0;
      right: 9%;
      @media screen and (max-width: 767px){
        width: 40%;
        bottom: 5%;
        right: 0;
      }
    }
  }
  .text {
    text-align: left;
    padding: 0 100px;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      padding: 0;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 20px !important;
    }
  }
  .line-img {
    width: 80%;
    margin: 30px 0;
    @media screen and (max-width: 767px){
    width: 100%;
    margin: 0 0 10px;
    }
  }
  .bottom {
    vertical-align: bottom;
  }
}
.sp{
  @media screen and (max-width: 767px){
    padding-bottom: 50px;
  }
}
</style>
