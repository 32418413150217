<template>
  <div class="home-invitation">
    <UiHeading :h="'2'" :type="'E2'" :text="'伏見会の内容について'" />

    <h3 style="font-family: '游明朝','游明朝体'">コミュニティの形態</h3>
    <p>
      伏見先生を中心としたLINEでのチャットグループ（オープンチャット）にご参加いただき、オープンチャットにて伏見先生からの情報を受け取っていただきます。<br />
      無料で提供する内容・企画と、有料で提供する内容・企画があり、有料の場合は、申込制として、申込者のみに案内をお送りします。<br />
      オープンチャットは、サロン名とお名前を表示して参加していただく必要がございます。
    </p>

    <h3 class="back-main" style="font-family: '游明朝','游明朝体'">
      無料で提供する内容<br class="sp-only" />（今後、変更の可能性あり）
    </h3>
    <div class="text-cover">
      <ul>
        <li>サロン運営の全般に関するノウハウの提供</li>
        <li>伏見先生からのモチベーションアップの言葉</li>
        <li>伏見先生、キレイ処の活動報告</li>
        <li>提供物（症例、検証データ、など）</li>
      </ul>
    </div>

    <h3 class="back-main" style="font-family: '游明朝','游明朝体'">
      有料で提供する内容<br class="sp-only" />（今後、変更の可能性あり）
    </h3>
    <div class="text-cover">
      <ul>
        <li>講習会（現地、オンライン）</li>
        <li>相談&質問会</li>
        <li>少人数勉強会、交流会、オフ会など伏見先生と交流できるイベント</li>
        <li>
          集客・マーケティング・販促に関するサービス（ホームページ制作、ネット広告、求人強化、など）
        </li>
      </ul>
    </div>
    <p class="note">
      ※伏見会の参加者からの希望を反映し、提供する内容を順次更新していく予定です。
    </p>
    <!-- <p class="min-text">
      伏見会の運営は、キレイ処の副社長を務める小関（おぜき）が統括をしています。伏見会の講義や企画などで、みなさんともお会いすること、お話しすることがあると思いますので、何卒よろしくお願いいたします。また、伏見会で「集客・マーケティング・販促・求人」に関する勉強会などを行う際は、小関が講師を務めさせていただきます。
    </p>
    <div class="ozeki">
      <img alt="小関貴久" src="@/assets/fushimi-HP-013.jpg" />
      <div class="right-cover">
        <h4>小関貴久について</h4>
        <p class="about-text">
          伏見先生と高校時代の同級生。キレイ処でのサロンケア歴8年、ADS使用歴8年超。ウェブマーケティング会社である株式会社グレイトヘルプを創業・経営して11年。2015年から株式会社グレイトヘルプとして、キレイ処の集客/マーケティングを全面的にバックアップ。その実績によって、現在はキレイ処の副社長として事業構築・マーケティング業務を統括。アフィリエイトで日本トップレベルの実績、連続起業家Dears北原孝彦氏へのコンサル実績、東海地区で100店舗超の美容系サロングループへのコンサル実績などウェブマーケティングにおける実績多数。ドクターリセラ商品、サロン集客、通販に精通している稀有なマーケターであり、伏見先生にとってのベストパートナー。
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";

export default {
  name: "membership-invitation",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  margin: 80px 0 30px;
  @media screen and (max-width: 767px) {
    margin: 50px 0 10px;
  }
}
.back-main {
  background-color: var(--main);
  color: white;
  padding: 20px 0;
}
.text-cover {
  font-size: 20px;
}
.min-text {
  margin: 50px 0;
}
.ozeki {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;
   @media screen and (min-width:768px) and ( max-width:1024px){
    //  flex-direction: column;
    align-items: flex-start;
   }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  img {
    width: 22%;
    @media screen and (max-width: 767px){
      width: 70%;
    }
  }
  .right-cover {
    width: 76%;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 50px;
    }
    h4 {
      font-size: 22px;
      margin-bottom: 30px;
      background: linear-gradient(transparent 60%, var(--sub) 60%);
      @media screen and (max-width: 767px){
        margin-bottom: 10px;
      }
    }
    .about-text {
      font-size: 16px;
    }
  }
}
.note{
  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>
