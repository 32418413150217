<template>
  <div class="top">
    <div class="cover">
      <div class="filter">
        <video ref="player" autoplay muted playsinline>
          <source src="video/hushimi-top.mp4" />
          このブラウザではサポートされていません。
        </video>
      </div>

      <!-- <div class="container" :class="{
        'animation-start':
          (path !== '/' && scrollY >= 0) || (path === '/' && scrollY >= 800),
      }" style="font-family: '游明朝','游明朝体'"> -->
       <div ref="container" class="container animation-start" style="font-family: '游明朝','游明朝体'">
        <p>ドクターリセラ ダイヤモンドマイスター</p>
        <p>乳化クレンジング考案者</p>
        <p class="fushimi">伏見 嘉洋</p>
        <p class="roma">Fushimi Yoshihiro</p>

        <img src="@/assets/fushimi-HP-pattern003.png" class="logo pc-only" />
        <img src="@/assets/fushimi-HP-pattern003sp.png" class="logo sp-only" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  mounted: function(){
    
    let player = this.$refs.player;
    let container = this.$refs.container;

    player.addEventListener('timeupdate', function(){
      if (player.currentTime > 13) {
        container.style.display = 'none';
        container.classList.remove("animation-start");
      }
    });

    player.addEventListener('ended', function(){
      console.log('ended');
      container.style.display = '';
      container.classList.add("animation-start");
      player.play();
    });
  },
};
</script>

<style lang="scss" scoped>
.cover {
  // background-color: rgb(213, 219, 229); //動画に黒のフィルターかける
  display: inline-block; //上記とセット
  position: relative;
  //ここからスマホ全画面用
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .filter {
    opacity: 0.8;
    display: block;
    // background-image: url(~@/assets/black.png);

    //ここからスマホ全画面用
    position: relative !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: no-repeat center center/cover;
  }

  video {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  /*動画よりも画面が縦に長くなるとき用*/
  @media (max-aspect-ratio: 16/9) {
    video {
      height: 100%;
      left: 80%;
      transform: translateX(-80%);
    }
  }

  .animation-start{
     animation: fadeout-anim2 10s linear forwards;
  }

  .container {
    position: absolute;
    top: 35%;
    left: 5%;
    // letter-spacing: 0.3em;
    font-size: 34px;
    z-index: 100;
    color: white;
    line-height: 1.5em;

    @media screen and (max-width: 767px) {
      writing-mode: vertical-rl;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      font-size: 24px;
    }

    .fushimi {
      font-size: 60px;
      margin-top: 35px;
      letter-spacing: 0.2em;
      @media screen and (max-width: 767px) {
        margin: 60px 25px 0;
      }
    }
    .roma {
      font-size: 23px;
      letter-spacing: 0.15em;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        margin: 62px 0 0;
      }
    }

    .logo {
      position: absolute;
      width: 120px;
      top: 45%;
      left: 43%;
      animation: rotate-anime 9s linear infinite;

      @media screen and (max-width: 767px) {
        top: 75%;
        left: 10%;
        margin-bottom: 20px;
        width: 90px;
      }
    }

    @keyframes fadeout-anim {
      100% {
        opacity: 0;
      }
    }
    @keyframes fadeout-anim2 {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes rotate-anime {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
