<template>
  <div class="ui-box">
    <div
      :class="{
        typeA: type === 'A',
        //1列：img
        //2列：text
        typeA2: type === 'A2',
        //タイトル+詳細ボタン付き
        typeA3: type === 'A3',
        //タイトル付き

        typeB: type === 'B',
        //1列(左：img 右：text)
        typeB2: type === 'B2',
        typeC: type === 'C',
        //1列(右：img 左：text)
      }"
    >
      <div class="cover">
        <div>
          <img
            :src="require(`@/assets/${imgPath}`)"
            :alt="{ imgName }"
            class="photo"
          />
        </div>
        <h3
          v-show="type === 'A2' || 'A3'"
          style="font-family: '游明朝','游明朝体'"
          v-html="boxTitle"
        ></h3>

        <div class="text-area">
          <p v-html="text"></p>
        </div>
        <div v-if="targetBlank === true">
        <a
          :href="`${linkPath}`"
          target="_blank"
          rel="noopener noreferrer"
          v-show="type === 'A2'"
          style="pointer-events: none;"
        >
          <div class="basicBox">
            Coming Soon
            <svg
              width="200"
              height="40"
              viewBox="0 0 200 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0" y="0" fill="var(--sub2)" width="200" height="40" />
            </svg></div
        ></a>
        </div>
        <div v-else-if="targetBlank === false">
        <a
          :href="`${linkPath}`"
          v-show="type === 'A2'"
          style="pointer-events: none;"
        >
          <div class="basicBox">
            Coming Soon
            <svg
              width="200"
              height="40"
              viewBox="0 0 200 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0" y="0" fill="var(--sub2)" width="200" height="40" />
            </svg></div
        ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiBox",
  components: {},
  props: {
    imgPath: String,
    imgName: String,
    text: String,
    linkPath: String,
    boxTitle: String,
    type: String,
    targetBlank:Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-box {
  .typeA,
  .typeA2,
  .typeA3 {
    padding: 50px 50px 35px;
    @media screen and (max-width: 767px) {
      padding: 30px 30px 15px;
    }
    .cover {
      display: flex;
      flex-direction: column;

      .photo {
        width: 100%;
      }
      .text-area {
        padding: 20px 0;
        height: 200px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          height: 250px;
          padding-top: 10px;
        }
        @media screen and (max-width: 767px) {
          height: auto;
          padding-top: 15px;
        }

        p {
          word-wrap: break-word;
        }
      }
    }
  }
  .typeA2,
  .typeA3 {
    h3 {
      color: var(--black);
      margin-top: 20px;
      font-size: 24px !important;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        height: 55px;
        margin-top: 0;
        display: flex;
        align-items: center;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        margin-top: 15px;
      }
    }
    .cover {
      justify-content: center;
      align-items: center;
      .zoom-in {
        overflow: hidden;
      }

      .zoom-in img {
        transition: 1s all;
        vertical-align: top;
      }
      .zoom-in img:hover {
        transform: scale(1.2, 1.2);
        transition: 1s all;
      }
    }
    .gradient4 {
      /*ボタンの形状*/
      display: inline-block;
      padding: 5px 60px;
      // border-radius:30px;
      text-decoration: none;
      border: 1px solid var(--sub2);
      color: var(--sub2);
      outline: none;
      /*アニメーションの指定*/
      transition: all 0.4s ease-out;
    }

    /*hoverした際、グラデーションと影を付ける*/
    .gradient4:hover {
      /*ボタンの形状*/
      border-color: transparent;
      color: #fff;
      /*背景の色と形状*/
      // background: linear-gradient(270deg, #fa6c9f 0%, #ffe140 50%, #ff357f 100%);
      background: linear-gradient(
        270deg,
        #a69d4e 0%,
        #4c4948 50%,
        #e9e3d8 100%
      );
      background-size: 200% auto;
      background-position: right center;
      /*ボックスの影*/
      box-shadow: 0 5px 10px rgb(166, 157, 78, 0.4);
    }
    .btnshine {
      /*キラッと光る基点とするためrelativeを指定*/
      position: relative;
      /*ボタンの形状*/
      display: inline-block;
      background: var(--main);
      color: #fff;
      padding: 5px 60px;
      text-decoration: none;
      border: 1px white solid;
      outline: none;
      overflow: hidden;
    }

    /*キラッと光る*/
    .btnshine::before {
      content: "";
      /*絶対配置でキラッと光るの位置を決める*/
      position: absolute;
      top: 0;
      left: -75%;
      /*キラッと光る形状*/
      width: 50%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(166, 157, 78, 0.3) 100%
      );
      transform: skewX(-25deg);
    }

    /*hoverした際の移動のアニメーション*/
    .btnshine:hover::before {
      animation: shine 0.7s;
    }

    @keyframes shine {
      100% {
        left: 125%;
      }
    }

    .btn {
      /*アニメーションの起点とするためrelativeを指定*/
      position: relative;
      overflow: hidden;
      /*ボタンの形状*/
      text-decoration: none;
      display: inline-block;
      border: 1px solid white; /* ボーダーの色と太さ */
      padding: 10px 30px;
      text-align: center;
      outline: none;
      /*アニメーションの指定*/
      transition: ease 1s;
    }

    /*ボタン内spanの形状*/
    .btn span {
      position: relative;
      z-index: 3; /*z-indexの数値をあげて文字を背景よりも手前に表示*/
      color: var(--black);
    }

    .btn:hover span {
      color: #fff;
    }

    /*== 背景が流れる（中央から外） */

    .bgcenterout:before {
      content: "";
      /*絶対配置で位置を指定*/
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      /*色や形状*/
      background: #333;
      width: 100%;
      height: 100%;
      /*アニメーション*/
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 0);
      transform-origin: center;
    }

    /*hoverした際の形状*/
    .bgcenterout:hover:before {
      transform: scale(1, 1);
    }
    .basicBox {
      width: 200px;
      height: 40px;
      margin: 15px auto;
      color: var(--black);
      // font-family: "Open Sans", sans-serif;
      font-size: 1.15rem;
      line-height: 40px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      cursor: pointer;

      &:hover {
        // color: #a69d4e;
        // transition-delay: 0.8s;
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
    svg rect,
    svg path,
    svg polyline {
      fill: none;
      stroke: var(--black);
      stroke-width: 1;
    }

    .basicBox:hover svg rect {
      stroke: var(--black);
    }

    /* Basic Box */
    svg rect {
      stroke-dasharray: 400, 0;
      -webkit-transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
    }
    .basicBox:hover svg rect {
      stroke-width: 3;
      stroke-dasharray: 38, 320;
      stroke-dashoffset: 38;
      -webkit-transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
    }
  }
  .typeB,
  .typeC {
    padding-top: 36px;
    .cover {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .photo {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .text-area {
        width: 48%;
        padding-top: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        p {
          word-wrap: break-word;
        }
      }
    }
  }
  .typeC {
    .cover {
      flex-direction: row-reverse !important;
    }
  }
  .typeB2 {
    padding-top: 36px;
    .cover {
      padding: 30px;
      display: flex;
      // flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-start;
      align-items: center;
      border: var(--sub) 8px solid;
      width: 80%;
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        display: block;
        width: 90%;
      }
      .photo {
        width: 25%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .text-area {
        width: 65%;
        padding-top: 0;
        text-align: center;

        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: left;
        }

        p {
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>