var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-box"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      //1列：img
      //2列：text
      typeA2: _vm.type === 'A2',
      //タイトル+詳細ボタン付き
      typeA3: _vm.type === 'A3',
      //タイトル付き

      typeB: _vm.type === 'B',
      //1列(左：img 右：text)
      typeB2: _vm.type === 'B2',
      typeC: _vm.type === 'C',
      //1列(右：img 左：text)
    }},[_c('div',{staticClass:"cover"},[_c('div',[_c('img',{staticClass:"photo",attrs:{"src":require(`@/assets/${_vm.imgPath}`),"alt":{ imgName: _vm.imgName }}})]),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'A2' || 'A3'),expression:"type === 'A2' || 'A3'"}],staticStyle:{"font-family":"'游明朝','游明朝体'"},domProps:{"innerHTML":_vm._s(_vm.boxTitle)}}),_c('div',{staticClass:"text-area"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),(_vm.targetBlank === true)?_c('div',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'A2'),expression:"type === 'A2'"}],staticStyle:{"pointer-events":"none"},attrs:{"href":`${_vm.linkPath}`,"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{staticClass:"basicBox"},[_vm._v(" Coming Soon "),_c('svg',{attrs:{"width":"200","height":"40","viewBox":"0 0 200 40","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0","y":"0","fill":"var(--sub2)","width":"200","height":"40"}})])])])]):(_vm.targetBlank === false)?_c('div',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'A2'),expression:"type === 'A2'"}],staticStyle:{"pointer-events":"none"},attrs:{"href":`${_vm.linkPath}`}},[_c('div',{staticClass:"basicBox"},[_vm._v(" Coming Soon "),_c('svg',{attrs:{"width":"200","height":"40","viewBox":"0 0 200 40","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0","y":"0","fill":"var(--sub2)","width":"200","height":"40"}})])])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }