<template>
  <div class="membership-flow">
    
          <UiHeading
      :h="'2'"
      :type="'E2'"
      :text="'伏見会への参加の流れ'"
      class="space-200"
    />
    <div class="box-cover">
      <UiFlowBox
        v-for="flowBoxListData in flowBoxList"
        :key="flowBoxListData.id"
        :no="flowBoxListData.no"
        :title="flowBoxListData.title"
        :yazirushi="flowBoxListData.yazirushi"
        :com="flowBoxListData.com"
        class="item"/>
    </div>




  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiFlowBox from "../Ui/FlowBox.vue";

export default {
  name: "HomeMap",
  components: {
    UiFlowBox,
    UiHeading,
  },
  props: {},
  data() {
    return {
      flowBoxList:[
        {
          id:1,
          no:"1",
          title:"伏見嘉洋公式LINEを<br class=sp-only>友だち追加する",
          yazirushi: true,
          com: true,
        },
        {
          id:2,
          no:"2",
          title:"伏見嘉洋公式LINEへ「サロン名、サロンコード、お名前（フルネーム）、役職（オーナー、スタッフ）」を送信",
          yazirushi: true,
        },
        {
          id:3,
          no:"3",
          title:"こちらで確認後、<br class=sp-only>伏見会オープンチャットへご招待",
          yazirushi: true,
        },
        {
          id:4,
          no:"4",
          title:"サロン名/お名前を設定して<br class=sp-only>伏見会オープンチャットへ参加",
          yazirushi: true,
        },
        {
          id:5,
          no:"5",
          title:"不定期で伏見先生から<br class=sp-only>メッセージが届きます",
          yazirushi: false,
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box-cover{
  padding-top: 20px;
}
</style>
