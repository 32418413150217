<template>
  <div class="fl-qa">
    <UiHeading :h="'2'" :type="'E2'" :text="'乳化クレンジングについてよくある質問'" />
    <div class="qa-list">
      <UiQaQaBox
        v-for="qaBoxListData in qaBoxList"
        :key="qaBoxListData.id"
        :qestion="qaBoxListData.qestion"
        :answer="qaBoxListData.answer"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiQaQaBox from "../Ui/Qa/QaBox.vue";

export default {
  name: "FlQA",
  components: {
    UiHeading,
    UiQaQaBox,
  },
  props: {},
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion:
            "現在準備中です",
          answer:
            "現在準備中です",
        },
        {
          id: 2,
          qestion: "現在準備中です",
          answer:
            "現在準備中です",
        },
        {
          id: 3,
          qestion:
            "現在準備中です",
          answer:
            "現在準備中です",
        },
        // {
        //   id: 4,
        //   qestion:
        //     "現在準備中です",
        //   answer:
        //     "現在準備中です",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.fl-qa {
  .qa-list {
    margin-top: 50px;
    .item{
      padding-bottom: 30px;
    }
  }

}
</style>
