import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({//kokoniメインカラーを設定
    theme: {
        themes: {
          light: {
            main: '#4e4a49',
            sub: '#ebebeb',
            sub2: '#c6c6c6',
            sub3: '#7a7676',
            black: '#7a7676',
          },
        },
      },
      options: {
        customProperties: true
      }
});
