<template>
  <div class="home-invitation">
      <img
        alt="ストライプ柄sp"
        src="@/assets/fushimi-HP-pattern001sp.png"
        class="sp-only" style="background-color: var(--sub);vertical-align: top;"
      />
    <div class="wrap" style="background-color: var(--sub)">
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only"
      />
      <div class="inner">
        <p>はじめまして、伏見嘉洋です。</p>

        <img alt="伏見嘉洋" src="@/assets/fushimi-HP-015.jpg" />
        <img
          alt="伏見嘉洋"
          src="@/assets/fushimi-HP-pattern002.png"
          class="stamp"
        />
        <p>
          僕は、20歳でエステティシャンとなり、岐阜県でフェイシャルエステサロン「キレイ処」をこれまで20年間に渡って4店舗経営してきました。
        </p>
        <img alt="キレイ処店舗" src="@/assets/fushimi-HP-016.jpg" />
        <p>
          世の中にはいろいろなフェイシャルエステサロンがありますが、キレイ処は「本当の肌改善の結果をお客様に提供する」という強い思いで運営をしています。
        </p>
        <p>
          このような思いでずっとサロンを経営しているのですが、サロンの創業当初は、自分が「これだ！」と自信を持って扱える化粧品がなく、エステサロンなのに化粧品の取り扱いをしていませんでした。
        </p>
        <p>
          珍しいサロンですよね。でも、どうしても納得して使用できる化粧品がなかったのです。
        </p>
        <p>
          そんな自分に転機が訪れたのは、サロンを経営し始めて、3年が経過したときでした。
        </p>
        <p>
          はじめて「これだ！」という化粧品に出会えたのがドクターリセラでした。
        </p>
        <p>
          それからというもの、ドクターリセラの化粧品をサロンに導入したことで、圧倒的な肌改善の実績をこれまで積み上げてくることができました。
        </p>
        <p>
          ドクターリセラは、お客様に本当の肌改善という結果を提供するにあたって、この上ない化粧品であり、エステサロンにとって、ぜひ導入していただきたい化粧品です。
        </p>
      </div>
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only bottom"
      />
    </div>
      <img
        alt="ストライプ柄sp"
        src="@/assets/fushimi-HP-pattern001sp.png"
        class="sp-only bottom"
        style="background-color: var(--sub);vertical-align: top;"
      />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox from "../Ui/Box.vue";

export default {
  name: "membership-invitation",
  components: {
    // UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.inner {
  width: 80%;
  margin: 0 auto;
  position: relative;
  padding: 50px 0;
  @media screen and (max-width: 767px){
    width: 100%;
  }
  p {
    margin-bottom: 20px !important;
  }
  img {
    margin: 20px 0;
  }
  .stamp {
    position: absolute;
    top: -60px;
    right: 0;
    width: 28%;
    @media screen and (max-width: 767px){
      width: 45%;
      right: -5%;
    }
  }
}
.bottom {
  vertical-align: bottom;
}
</style>
