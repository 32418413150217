<template>
  <div class="Ui-DetailButton">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeC: type === 'C',
      }"
    >
      <div class="cover">
        <router-link :to="`${path}`">
          <div class="button">
            <div class="arrow-before"></div>
            <p>{{ text }}</p>
            <div class="arrow"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiDetailButton",
  components: {},
  props: {
    path: String,
    text: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.typeA {
  .cover {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
      display: flex;
      align-items: center;
      p {
        font-weight: bold;
      }
    }
    .arrow {
      position: relative;
      display: inline-block;
      padding-left: 20px;
    }
    .arrow::before {
      content: "";
      width: 15px;
      height: 15px;
      border: 0px;
      border-top: solid 4px var(--main);
      border-right: solid 4px var(--main);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
    }
  }
  a {
    text-decoration: none;
    color: var(--black);
    &:hover {
      opacity: 0.8;
    }
  }
}

.typeB {
  .cover {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .button {
      display: flex;
      align-items: center;
      p {
        font-weight: bold;
      }
    }
    .arrow-before {
      position: relative;
      display: inline-block;
      padding-right: 20px;
    }
    .arrow-before::before {
      content: "";
      width: 15px;
      height: 15px;
      border: 0px;
      border-top: solid 4px var(--main);
      border-right: solid 4px var(--main);
      -ms-transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -9px;
    }
  }
  a {
    text-decoration: none;
    color: var(---black);
    &:hover {
      opacity: 0.8;
    }
  }
}
.typeC {
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 30px;
      border: 2px solid white;
      height: 50px;
      background-color: white;
      transition: 0.5s;
      p {
        font-weight: 400;
      }
      &:hover{
        background-color: var(--main);
      }
    }
    a {
      text-decoration: none;
      color: var(--main);
      transition: 0.5s;

      &:hover {
        color: var(--white);
      }
    }
  }
}
</style>