<template>
  <div class="home-services wrap">
    
    <h3 style="font-family: '游明朝','游明朝体'" class="space-top-150">サロンオーナー向けサービス</h3>
    <div class="box-cover">
      <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-001.jpg'"
        :imgName="'伏見会'"
        :linkPath="'/membership01/'"
        :text="'ドクターリセラ販売代理店に加盟するエステサロンオーナー、および、店長クラスに向けた、エステサロンの経営に必要なノウハウを総合的にお教えする「伏見会」を運営しています。'"
        :boxTitle="'伏見会'"
        :targetBlank="false"
        class="box"
      />
        <!-- :linkPath="'membership01/'" -->
      <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-002.jpg'"
        :imgName="'ドクターリセラ代理店加盟サポート'"
        :linkPath="'membership02/'"
        :text="'ドクターリセラ販売代理店への加盟について、伏見嘉洋に直接お話と相談ができます。ドクターリセラの使い手として、日本一の称号を持ち、4店舗のサロンを経営する伏見嘉洋に相談の上、加盟の検討をすることができます。'"
        :boxTitle="'ドクターリセラ<br class=sp-only>代理店加盟サポート'"
        :targetBlank="false"
        class="box"
      />
    </div>


    <h3 class="space-150" style="font-family: '游明朝','游明朝体'">サロン/オンラインショップ</h3>
    <div class="box-cover">
            <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-005.jpg'"
        :imgName="'キレイ処'"
        :linkPath="'https://kireidokoro.jp/'"
        :text="'ドクターリセラ主催のリセラクイーンコンテストで数々の実績を生み出してきた実績日本一のフェイシャルエステサロン「キレイ処」を4店舗運営しています。脱毛の施術もしています。'"
        :boxTitle="'キレイ処'"
        :targetBlank="true"
        class="box"
      />
      <UiBoxComingsoon
        :type="'A2'"
        :imgPath="'fushimi-HP-003.jpg'"
        :imgName="'ドクターリセラ<br class=sp-only>パーフェクトガイド'"
        :linkPath="''"
        :text="'ドクターリセラの製品を購入できるオンラインショップです。ドクターリセラの使い手として最高峰の称号「ダイヤモンドマイスター」に輝く伏見嘉洋独自の使用方法を学べます。'"
        :boxTitle="'ドクターリセラ<br class=sp-only>パーフェクトガイド'"
        :targetBlank="true"
        class="box"
      />
        <!-- :linkPath="'https://drr-guide.com/'" -->
      <!-- <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-004.jpg'"
        :imgName="'低糖質専門店 結'"
        :linkPath="'https://lowcarboyui.base.shop/'"
        :text="'普通のチーズケーキ1個分と結のチーズケーキ14.4個分で同じ糖質量。それなに、とても濃厚で甘くておいしい。美容・健康を意識する人のために、低糖質と原材料にこだわり抜いた商品を販売しています。'"
        :boxTitle="'低糖質専門店 結'"
        :targetBlank="true"
        class="box"
      /> -->
    </div>

        <!-- <h3 class="space-150" style="font-family: '游明朝','游明朝体'">サロン/ストア</h3>
    <div class="box-cover"> -->
      <!-- <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-005.jpg'"
        :imgName="'キレイ処'"
        :linkPath="'https://kireidokoro.jp/'"
        :text="'ドクターリセラ主催のリセラクイーンコンテストで数々の実績を生み出してきた実績日本一のフェイシャルエステサロン「キレイ処」を4店舗運営しています。脱毛の施術もしています。'"
        :boxTitle="'キレイ処'"
        :targetBlank="true"
        class="box"
      /> -->
      <!-- <UiBox
        :type="'A2'"
        :imgPath="'fushimi-HP-006.jpg'"
        :imgName="'低糖質専門店 結'"
        :linkPath="'https://lowcarboyui.com/'"
        :text="'低糖質専を専門としたお食事とケーキを提供しています。広々とした店内は、結婚式の二次会などのパーティーでもご利用いただけます。'"
        :boxTitle="'低糖質専門店 結'"
        :targetBlank="true"
        class="box"
      /> -->
    <!-- </div> -->


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBox from "../Ui/Box.vue";
import UiBoxComingsoon from "../Ui/BoxComingsoon.vue";

export default {
  name: "HomeMap",
  components: {
    UiBox,
    UiBoxComingsoon,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .box {
    width: 48%;
    background-color: white;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      width: 100%;
    }

  }
}
h3{
  text-align: center;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
</style>
