<template>
  <div class="home">
    <div class="top">
      <HomeTop />
    </div>

    <UiHeading
      :h="'2'"
      :type="'E2'"
      :text="'事業内容'"
      class="space-200 space-btm-100"
    />

    <div
      class="space-top-70 space-top-150p space-btm-150p"
      style="background-color: var(--sub)"
    >
      <HomeServices id="service" />
    </div>

    <div class="space-top-70 space-top-150">
      <HomeAboutFushimi id="aboutus" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeServices from "@/components/Home/Services.vue";
import HomeTop from "@/components/Home/Top.vue";
import HomeAboutFushimi from "@/components/Home/AboutFushimi.vue";
import UiHeading from "@/components/Ui/Heading.vue";

export default {
  name: "Home",
  components: {
    HomeTop,
    HomeServices,
    HomeAboutFushimi,
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.space-btm-100 {
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}
</style>
