<template>
  <div class="ui-fushimi-history">
  
    <div class="contests">
      <h3 style="font-family: '游明朝','游明朝体'">
        「リセラクイーンコンテスト」で優勝3回、<br />準優勝5回、特別賞２回、<br class="sp-tb-only"/>入賞回数合計10回は全国第1位の実績
      </h3>
      <img alt="リセラクイーンコンテスト" src="@/assets/fushimi-HP-007.jpg" />
      
      <h3 style="font-family: '游明朝','游明朝体'">
        2016年開催の<br class="sp-tb-only"/>トップオブリセラクイーンコンテストでも優勝
      </h3>
      <img alt="トップオブリセラクイーンコンテスト優勝" src="@/assets/fushimi-HP-008.jpg" />

      <h3 style="font-family: '游明朝','游明朝体'">
        ドクターリセラ株式会社から<br class="sp-tb-only"/>ダイヤモンドマイスターに認定
      </h3>
      <img alt="ダイヤモンドマイスター" src="@/assets/fushimi-HP-009.jpg" />

      <h3 style="font-family: '游明朝','游明朝体'">
        テレビ・ラジオ・雑誌など<br class="sp-only"/>メディア掲載実績多数
      </h3>
      <img alt="メディア掲載実績" src="@/assets/fushimi-HP-010.jpg" />

      <p  class="zisseki-coment" v-show="coment === true">キレイ処をこれまで20年間運営し、20,000人以上の肌改善に携わってきました。キレイ処4店舗は、新規来店のお客様のコース契約の成約率が90%を超えていて、既存のお客様のリピート来店で、予約の枠は3ヶ月先までほぼ埋まっている状態が続いています。</p>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UiFushimiHistory",
  components: {
  },
  props: {
    coment:Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

.contests {
  text-align: center;
  h3{
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  img {
    width: 70%;
    margin: 20px 0 150px;
    @media screen and (max-width: 767px) {
      width: 100%;
    margin: 20px 0 50px;
    }
  }
  
}
.space-200{
  @media screen and (max-width: 767px) {
    margin-top: 50px;
    
  }
}
.zisseki-coment{
 text-align: left;
 margin-top:-100px;
 @media screen and (max-width: 767px) {
  margin-top: 0;
 }
}
</style>
