<template>
  <div class="layout-footer">
    <div class="footer footer-sp-bottom">
      <div class="copyright">
        <p>Copyright © <br class="sp-only"/>fushimi-yoshihiro.com All Rights Reserved.</p>
      </div>

<!-- 明日コンポーネントにする -->
      <!-- <div class="mobile-fixed">
        <div class="contact">
          <a
            href="https://lowcarboyui.base.shop"
            target="_blank"
            rel="noopener noreferrer"
            ><span>オンラインショップ</span>
          </a>
        </div>
        <div class="tel">
          <a href="tel:0587368228">
            <img :src="`${rootUrl}/img/yui-top-pa001.png`" alt="ロゴ" /><span
              >お電話でのお問い合わせ</span
            >
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--main);
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    align-items: center;
  }

  .copyright {
    font-size: 16px;
    color: white;
    margin-right: 150px;
    margin-bottom: 50px;
    font-family: "游明朝体"!important;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 14px;
    }
  }
}
.mobile-fixed {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 10px;
    width: 100%;
    height: 2.5em;
    // background-color: rgba(255, 255, 255, 0.7);
    align-items: center;
    z-index: 999;

    a {
      text-decoration: none;
      color: white;
    }

    .contact,
    .tel {
      width: 48%;
      text-align: center;
      padding: 10px 0;
      font-size: 13px;
      vertical-align: middle;
      margin: 0;

      img {
        width: 10px;
        vertical-align: middle;
        margin-right: 3px;
      }
      span {
        vertical-align: middle;
      }
    }
    .contact {
      // background-color: #CBE9E9;
     background-color: #545454;
      color: white;
      // height: 35px;
    }
    .tel {
      background-color: var(--main);
      // color: black!important;
      // font
    }
  }
}

.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
</style>
