<template>
  <div class="membership-flow">
    
          <UiHeading
      :h="'2'"
      :type="'E2'"
      :text="'伏見会への参加の流れ'"
      class="space-200"
    />
    <div class="box-cover">
      <UiFlowBox
        v-for="flowBoxListData in flowBoxList"
        :key="flowBoxListData.id"
        :no="flowBoxListData.no"
        :title="flowBoxListData.title"
        :yazirushi="flowBoxListData.yazirushi"
        :com="flowBoxListData.com"
        class="item"/>
    </div>




  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiFlowBox from "../Ui/FlowBox.vue";

export default {
  name: "HomeMap",
  components: {
    UiFlowBox,
    UiHeading,
  },
  props: {},
  data() {
    return {
      flowBoxList:[
        {
          id:1,
          no:"1",
          title:"以下のお問い合わせフォームから必要な情報を記入して申し込みをします。",
          yazirushi: true,
        },
        {
          id:2,
          no:"2",
          title:"こちらで申し込み内容を確認後、<br class=sp-only>相談の日程を調整します。",
          yazirushi: true,
        },
        {
          id:3,
          no:"3",
          title:"相談日の前に、<br class=sp-only>遠隔会議のURLをお送りします。",
          yazirushi: true,
        },
        {
          id:4,
          no:"4",
          title:"相談の日時になりましたら、<br class=sp-only>遠隔会議に参加します。",
          yazirushi: true,
        },
        {
          id:5,
          no:"5",
          title:"相談の上、ドクターリセラ製品の代理店への加盟を決定されたら、<br class=sp-only>申し込みをします。",
          yazirushi: false,
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.box-cover{
  padding-top: 20px;
}
</style>
