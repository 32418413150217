<template>
      <div class="youtube-button">
        <a
          href="https://www.youtube.com/@fushimi243"
          target="_blank"
          rel="noopener noreferrer"
          >伏見嘉洋のYouTubeチャンネルを見る</a
        >
      </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ui-add-button",
  components: {},
  props: {
    // path: String,
    // text: String,
    // type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.youtube-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
  a {
    color: var(--black);
    display: inline-block;
    border: 1px solid var(--black);
    padding: 10px 20px;
    transition: 1s;

    &:hover {
      cursor: pointer;
      background-color: var(--sub);
    }
  }
}
</style>