<template>
  <v-app>
    <Header :path="path" />
    <v-main>
      <router-view :class="{ 'body-top': path !== '/' }" />
      <ToTopButton :path="path" />
    </v-main>
    <Footer :path="path" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap");
:root {
  --black: #25201f;
  --main: #4e4a49;
  --sub: #ebebeb;
  --sub2: #c6c6c6;
  --sub3: #7a7676;
  --line: rgb(6, 199, 85);
}

#app {
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 300;
  color: var(--black);
  line-height: 2em;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  // -webkit-backface-visibility: hidden;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.sp-tb-only{
  display: none;
  @media screen and (max-width: 1024px) {
    display: initial !important;
} 
}

.tb-only {
  display: none;
   @media screen and (min-width:768px) and ( max-width:1024px) {
     display: initial !important;
  }
}
.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.body-top {
  margin-top: 70px;
}

h1,
h2 {
  font-size: 32px;
  line-height: 1.5em;
  @media screen and (max-width: 767px){
    font-size: 24px;
  }
}
h3 {
  font-size: 28px;
  line-height: 1.5em;
  @media screen and (max-width: 767px){
    font-size: 20px;
  }
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
.v-application p {
  margin-bottom: 0 !important;
}
a {
  text-decoration: none;
}
.space-50 {
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}
.space-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}
.space-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-150p {
  padding-top: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
.space-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}


//ヘッダー用のスペースレンジャー
.space-top-70 {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
//共通スペースレンジャー
.space-btm-100 {
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}
.space-btm-50p {
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}
.space-btm-100p {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
}
.space-btm-150p {
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-bottom: 60px;
  }
}
.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}

ul {
  li {
    font-size: 20px;
    list-style-type: none;
    line-height: 2;
    position: relative;
    padding-left: 24px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  li::before {
    content: "◎";
    margin-right: 10px;
      left: 0;
  position: absolute;
}
    @media screen and (max-width: 767px){
      margin-right: 0;
    }
  }

.v-application ul{
@media screen and (max-width: 767px){
  padding-left: 0!important;
}
}
.youtube {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.youtube iframe {
  width: 100%;
  height: 100%;
}

</style>
