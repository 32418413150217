<template>
  <div class="layout-header">
    <div
      class="header-cover"
      :class="{
        'over-baseline':
          (path !== '/' && scrollY >= 0) || (path === '/' && scrollY >= 800),
      }"
    >
      <div class="header">
        <div class="logo" @click="top">
          <img
            src="@/assets/fushimi-logo.png"
            alt="ロゴ"
            :class="{
              'over-baseline2':
                (path !== '/' && scrollY >= 0) ||
                (path === '/' && scrollY >= 800),
            }"
          />
        </div>
        <div class="hamburger">
          <UiDrawer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      documentheight: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-header {
  .header-cover {
    z-index: 9999;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
  .header {
    padding: 0 50px;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    height: 70px;
    transition: all 2s;
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
    .logo {
      display: none;
    }
  }
  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.7;
    }
  }
}
.over-baseline {
  display: initial;
  transition: all 2s;
  height: 70px;
  .header {
    justify-content: space-between;
    background-color: var(--main);
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  // background-color: rgba(0, 0, 0, 0);
}
</style>
