<template>
  <div class="home-activity">
    <img
      alt="ストライプ柄sp"
      src="@/assets/fushimi-HP-pattern001sp.png"
      class="sp-only"
      style="background-color: var(--sub); vertical-align: top"
    />
    <div class="wrap" style="background-color: var(--sub)">
      <img
        alt="ストライプ柄"
        src="@/assets/fushimi-HP-pattern001.png"
        class="pc-only"
      />
      <div class="inner">
        <div class="inner-top">
          <h2 style="font-family: '游明朝','游明朝体'">伏見先生のビジョン</h2>
          <img
            alt="伏見嘉洋"
            src="@/assets/fushimi-HP-pattern002.png"
            class="stamp"
          />
        </div>
        <div class="text top30">
        <p>僕は、日本中からお肌のことで悩む人をなくしたい、一人でも多くの人を救いたいと考えています。</p>
        <p>僕自身、昔はお肌のことで悩みがあり、そのせいでストレスもあり、暗い気持ちで毎日を送っていました。</p>
        <p>このような過去があり、スキンケア・美容についてとことん追求してきました。</p>
        <p>その結果、乳化クレンジングを考案したり、ドクターリセラの各化粧品の効果的な使い方を導き出したりすることで、自分自身がお肌のことで悩むことがなくなり、明るい気持ちで日々を過ごせるようになりました。</p>
        <p>そして、自分自身が運営するキレイ処では、スタッフに自分のノウハウと技術を教育することで、これまで20,000名程度のお客様の肌改善に携わり、人生を変えることができました。</p>
        <p>手前味噌ではありますが、僕がこれまで追求してきたドクターリセラ商品の使い方、スキンケアのノウハウ、美容知識、サロン経営のノウハウは、それくらい確かたるものであり、日本一だと自負しています。</p>
        <p>しかし、キレイ処にも日々お客様が絶えないとおり、日本中にはまだまだお肌のことで悩んでいる人がいます。</p>
        <p>僕は、その人たちも肌改善で幸せにしたい、幸せをつかんでほしい、そうできると考えています。</p>
        <p>そのためには、やはり同じドクターリセラを使用する全国のサロン様の力が必要だという結論に行き着きました。</p>
        <p>サロンに来店されるお客様がしっかりと結果を出すには、やはりフェイス・トゥ・フェイスで肌の様子を見ること、化粧品の使用方法を指導することなどが重要な役割を果たします。</p>
        <p>僕一人やキレイ処だけでは、したくても、到底できることではありません。</p>
        <p>だからこそ、サロンを統括し、スタッフを教育するサロンオーナー様、日々お客様と向き合うスタッフ様に、伏見会を通して、僕が持つノウハウや知識、経験などをもっともっと伝えて、力になりたいです。</p>
        <p>そして、伏見会に参加するサロンは、伏見会を通して、サロン経営に必要なすべてを学び、サロンをもっともっと良くしていっていただきたいです。</p>
        <p>良い意味で僕を頼りにしてほしいです。</p>
        <p>そのために、僕自身、伏見会を通しての活動、YouTubeやSNSを通しての情報発信によるドクターリセラ商品と加盟サロンの認知拡大に注力していきます。</p>
        <p>僕が全力で先頭を走り、サロン経営の向上、肌改善のノウハウの提供、ドクターリセラ商品とその魅力の認知拡大などを実現していきます。</p>
        <p>僕と一緒に明るい未来を一緒に作っていきましょう。</p>
        </div>

        <img
          alt="ストライプ柄"
          src="@/assets/fushimi-HP-pattern001.png"
          class="pc-only bottom"
        />
      </div>
    </div>
    <img
      alt="ストライプ柄sp"
      src="@/assets/fushimi-HP-pattern001sp.png"
      class="sp-only bottom"
      style="background-color: var(--sub); vertical-align: top"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiBox from "../Ui/Box.vue";

export default {
  name: "membership-invitation",
  components: {
    // UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.inner {
  text-align: center;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
  .inner-top {
    position: relative;
    padding-top: 100px;
      @media screen and (max-width: 767px){
    padding-top: 50px;
  }

    .stamp {
      position: absolute;
      width: 22%;
      bottom: 0;
      right: 9%;
      @media screen and (max-width: 767px){
        right: -5%;
        bottom: 60%;
        width: 35%;
      }
    }
  }
  .text {
    text-align: left;
    padding: 0 100px;
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      padding: 0;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 20px !important;
    }
  }
  .top30 {
    margin-top: 30px;
    @media screen and (max-width: 767px){
      // margin-top: 0;
    }
  }
  .youtube-img {
    width: 70%;
    margin: 50px 0;
    @media screen and (max-width: 767px){
      width: 100%;
      margin: 10px 0;
    }
  }

  .bottom {
    vertical-align: bottom;
  }
}
.sp{
  @media screen and (max-width: 767px){
    padding-bottom: 50px;
  }
}
</style>
