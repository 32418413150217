<template>
  <div class="ui-flow-box">
    <div class="outer">
      <div class="bar-box">
        <div class="bar-no">
          <p>{{ no }}</p>
        </div>
        <div class="bar-text">
          <p v-html="title" class="title"></p>
          <div class="add-button" v-show="com === true">
            <img
              alt="QRコード"
              class="qr"
              src="https://qr-official.line.me/gs/M_177zuyrf_BW.png?oat__id=1583346"
            />
            <a
              data-v-4185e1d6=""
              href="https://lin.ee/Yp66vzJ"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                data-v-4185e1d6=""
                src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                alt="友だち追加"
                border="0"
                class="line"
            /></a>
            <!-- <a href="http://" target="_blank" rel="noopener noreferrer">
        <img alt="LINEボタン" class="line" src="@/assets/line_button.png" />
        </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="svg-cover">
      <svg
        data-name="矢印"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 39"
        width="50px"
        height="50px"
        v-show="yazirushi === true"
      >
        <defs></defs>
        <polygon class="cls-1" points="0 0 32 39 64 0 0 0" />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiAddButton from "@/components//Ui/AddButton.vue";
export default {
  name: "UiFlowBox",
  components: {
    // UiAddButton,
  },
  props: {
    no: String,
    title: String,
    // text: String,
    yazirushi: Boolean,
    com: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  font-family: "游明朝" !important;
  background-color: white;
  margin-top: 10px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  .bar-box {
    display: flex;
    background-color: var(--sub);
    align-items: center;
    height: 100px;
    @media screen and (max-width: 767px) {
      height: auto;
      padding: 20px 0;
      flex-direction: column;
    }
    .bar-no {
      min-width: 120px;
      height: 50px;
      border-right: 1px solid #a7a5a4;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        border-right: 1px solid var(--main);
        border-left: 1px solid var(--main);
        margin-bottom: 10px;
        min-width: 80px;
        height: 30px;
      }
      p {
        font-size: 27px;
        color: var(--black);
        @media screen and (max-width: 767px) {
        font-size: 22px;
        }
      }
    }
    .bar-text {
      width: calc(100% -160px);
      padding-left: 30px;
      padding-right: 30px;
      // min-height: 137px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0px 20px;
        // min-height: 100px;
      }

      .title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 5px;
        @media screen and (max-width: 767px) {
          // padding-top: 10px;
          text-align: center;
          font-size: 18px !important;
        }
      }
    }
  }
}
.svg-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  .cls-1 {
    fill: var(--main);
  }
}
.v-application .title {
  font-family: "游明朝" !important;
  font-size: 22px !important;
}
.add-button {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 30%;
    margin-left: 15px;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    margin-top: 10px;
  }
  a {
    display: inline-block;
    width: 60%;
  }
  .qr {
    width: 35%;
  }
  .line {
    margin: 0;
    padding: 0;
    width: 100%;
    vertical-align: middle;
  }
}
</style>