<template>
  <div class="membership02">
    <div class="member02-top">
      <div class="wrap space-150p">
        <h1 style="font-family: '游明朝','游明朝体'">
          症例数日本一、<br />加盟サロン3,000店超の<br
            class="sp-only"
          />ドクターリセラ化粧品の<br />販売代理店サロンになりませんか？
        </h1>
        <div class="top-img">
          <img
            alt="ドクターリセラ化粧品"
            src="@/assets/fushimi-HP-014.jpg"
            class="aqua-venus"
          />
          <img
            alt="症例写真"
            src="@/assets/fushimi-HP-bfore&after.png"
            class="case"
          />
        </div>
      </div>
    </div>
    <div class="wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'こんな悩みはありませんか？'" />
      <ul class="space-50">
        <li>お客様に肌改善の結果を提供できていない</li>
        <li>肌トラブルが起きてしまい、それに向き合うのが怖い</li>
        <li>現在取り扱っている商材に満足・納得していない</li>
        <li>近隣のサロンにお客様を奪われている</li>
        <li>新規の集客に困っている</li>
        <li>自分のサロン経営に自信がない、もっと良くしていきたい</li>
        <li>これからサロンを開業するにあたり、何をしていか分からない</li>
        <li>これからサロンを開業するにあたり、不安なことがたくさんある</li>
        <li>
          自分でもサロンオーナーになれるか、先輩サロンオーナーに相談したい
        </li>
      </ul>
    </div>

    <div class="space-200">
      <Membership02Message />
    </div>

    <div class="space-200 wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'伏見嘉洋の実績'" />

      <div class="purpose space-50">
        <p>
          「肌改善」という分野において、日本でも有数の実力・実績を誇る男性エステティシャン。肌改善への探究心と独自のアプローチの研究と検証によって「結果につながる肌改善のノウハウ」を確立。ドクターリセラ化粧品の使い手として、ドクターリセラ株式会社とのタイアップによって加盟サロンに向けた講師活動を2011年から行っている。岐阜県でキレイ処を4店舗経営。株式会社K・R・I代表取締役。
        </p>
        <div class="youtube space-50">
          <iframe
            width="100%"
            height="550px"
            src="https://www.youtube.com/embed/FLPYUG4g94c"
            frameborder="0"
            allowfullscreen
          ></iframe>
          <UiYoutubeButton />
        </div>
      </div>
    </div>

    <UiFushimiHistory class="space-100 wrap" :coment="true" />

    <div class="space-200 wrap">
      <UiHeading :h="'2'" :type="'E2'" :text="'ドクターリセラについて'" />
      <img
        alt="ドクターリセラ化粧品2"
        src="@/assets/fushimi-HP-017.jpg"
        class="recella space-50"
      />
      <p class="text space-50">
        数ある化粧品メーカーの中で、ドクターリセラは肌改善の症例数日本一の化粧品メーカーです。顧問ドクターによる製品開発により、安心・安全・結果の３軸を実現した化粧品で、全国3,000店舗以上のエステサロンで使用されています。体に良いこと、本当の肌改善ができること、これがドクターリセラの化粧品の最大の特徴です。
      </p>
    </div>

    <div class="space-200 wrap">
      <UiHeading
        :h="'2'"
        :type="'E2'"
        :text="'ドクターリセラ製品の<br class=sp-only>取り扱いによるメリット'"
      />

      <p class="text space-50">
        やはり、最大のメリットは、圧倒的な肌改善の結果をお客様に提供できることです。これによって、お客様の満足度・継続率を高めることができ、エステサロンの経営として、理想的な状態にすることができる化粧品です。
      </p>
      <p class="text m-top">
        声を大きくして言うことはできませんが、世の中には結果を提供できないエステサロンが多くあります。そのようなサロンは、必然的に、優秀な人材が集まりづらい、定着しない、という問題があります。特に、働くスタッフとしては、お客様に結果を提供できるやりがいがある仕事か、お客様に結果を提供できない後ろめたさがある仕事か、という点で大きな影響を与えます。
      </p>
      <p class="text m-top">
        このように、ドクターリセラの製品を取り扱うことで、サロンの運営状況が今以上に良くなることが期待できます。
      </p>
    </div>

    <div class="space-200 wrap">
      <UiHeading
        :h="'2'"
        :type="'E2'"
        :text="'ドクターリセラの代理店への<br class=sp-tb-only>加盟に興味を持たれたら、<br>伏見嘉洋が<br class=sp-only>無料で相談会を行います。'"
      />

      <p class="text space-50">
        ドクターリセラの代理店への加盟に興味を持たれましたら、伏見嘉洋が1時間程度の面談（Zoom、または、岐阜までお越しいただく）を行い、
      </p>
      <ul class="in-list">
        <li>リセラ化粧品について</li>
        <li>サロンでのリセラ化粧品の取り扱いについて</li>
      </ul>
      <p>
        ということを説明の上、あなたから、「サロン経営において不安なこと、悩みごと」などの相談や質問などにすべてお答えし、加盟サロンになるかということを判断していただくことができます。
      </p>
      <p style="margin-top: 30px">
        ※このページからのみ、伏見嘉洋への相談の申し込みができます。
      </p>
    </div>

    <div class="wrap">
      <div class="space-150">
        <Membership02Flow />
      </div>
      <div class="space-150 contact space-btm-50p space-btm-100">
      <Membership02ContactForm />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import Membership02Message from "@/components/Membership02/Message.vue";
import UiFushimiHistory from "@/components/Ui/FushimiHistory.vue";
import UiYoutubeButton from "@/components/Ui/YoutubeButton.vue";
import Membership02Flow from "@/components/Membership02/Flow.vue";
import Membership02ContactForm from "@/components/Membership02/ContactForm.vue";

export default {
  name: "Membership02",
  components: {
    Membership02Message,
    UiHeading,
    UiFushimiHistory,
    UiYoutubeButton,
    Membership02Flow,
    Membership02ContactForm,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.member02-top {
  background-image: url(~@/assets/fushimi-HP-back001.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 230px;
  @media screen and (max-width: 767px) {
    background-image: url(~@/assets/fushimi-HP-back001sp.jpg);
    padding-bottom: 150px;
  }
}
h1 {
  font-size: 40px;
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
}
.top-img {
  margin-top: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .aqua-venus {
    width: 60%;
    padding-bottom: 35px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .case {
    width: 35%;
    @media screen and (max-width: 767px) {
      width: 70%;
      margin: 0 auto;
    }
  }
}

.text {
  text-align: left;
}
.m-top{
margin-top: 20px;
}
.in-list {
  margin: 30px 0;
  @media screen and (max-width: 767px) {
    li{
      font-weight: bold;
    }
  }
}
.contact{
  background-color: var(--sub);
  @media screen and (max-width: 767px) {
    padding: 0 8px;
  } }
</style>
